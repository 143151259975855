<script setup lang="ts">
interface Props {
  icon: string;
  size?: string;
  name: string;
  colour?: string;
  badge?: string | number;
  disabled?: boolean;
  classes?: string | [];
  asd: Function;
}

withDefaults(defineProps<Props>(), {
  size: "w-3 h-3",
});
</script>

<template>
  <div class="flex items-center justify-center" :class="{ relative: badge }">
    <div class="flex cursor-pointer items-center justify-center" :class="[classes,'text-dark-gray']">
      <font-awesome-icon :icon="icon" :class="size" aria-hidden="true" @click="asd(name)"></font-awesome-icon>
    </div>
  </div>
</template>
