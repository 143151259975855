import { library, config } from "@fortawesome/fontawesome-svg-core";
import {
  faCirclePlus,
  faPlus,
  faEllipsisVertical,
  faEnvelope,
  faXmark,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faCircleCheck,
  faCircleExclamation,
  faCheck,
  faBell,
  faGear,
  faHouse,
  faCreditCard,
  faBars,
  faLocationDot,
  faLock,
  faTent,
  faTrash,
  faShop,
  faPen,
  faPenToSquare,
  faRightFromBracket,
  faUpload,
  faUser,
  faFaceFrown,
  faCircleXmark,
  faIdCard,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";

// config.autoAddCss = false;

library.add(
  faBell,
  faCirclePlus,
  faPlus,
  faEllipsisVertical,
  faEnvelope,
  faXmark,
  faChevronUp,
  faChevronRight,
  faChevronLeft,
  faCircleCheck,
  faCircleExclamation,
  faCircleXmark,
  faCheck,
  faGear,
  faLocationDot,
  faLock,
  faHouse,
  faIdCard,
  faCreditCard,
  faBars,
  faTent,
  faFileInvoice,
  faTrash,
  faShop,
  faPen,
  faPenToSquare,
  faRightFromBracket,
  faUpload,
  faUser,
  faFaceFrown
);

export { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
