<script setup lang="ts">
import { reactive, toRefs, ref, computed } from "vue";
import VueSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";
import { splitThousands } from "@/helpers/split-thousands";

interface Props {
  context: any;
}

const props = defineProps<Props>();

const formatValue = (val: number | string) => {
  if (typeof val === "string") return val;
  if (props.context.currencySymbol) return `${props.context.currencySymbol}${splitThousands(val)}`;
  return splitThousands(val);
};

const modelValue = computed({
  get() {
    if (!props.context._value) return [props.context.min, props.context.max];
    return props.context._value;
  },
  set(value) {
    props.context.node.input(value);
  },
});
</script>

<template>
  <vue-slider
    :min="context.min"
    :max="context.max"
    v-model="modelValue"
    tooltip="always"
    :tooltip-formatter="(val: string | number) => formatValue(val)"
    tooltipPlacement="bottom"
  />
</template>
