<script setup lang="ts">
import { VueTelInput } from "vue-tel-input";
// import "vue-tel-input/dist/vue-tel-input.css";

interface Props {
  context: any; // FormKit
}

const props = withDefaults(defineProps<Props>(), {
  context: {},
  modelValue: null,
});
const inputState = computed(() => props.context.state ?? {});
const modelValue = computed({
  get() {
    return props.context._value;
  },
  set(value: any) {
    if (typeof value === "string") value.trim();
    props.context.node.input(value);
  },
});
</script>

<template>
  <vue-tel-input
    v-model="modelValue"
    :inputOptions="{ placeholder: context.placeholder }"
    valid-characters-only
    mode="international"
    :class="!inputState.valid && inputState.submitted ? 'border-danger' : 'border-light-gray'"
    :auto-default-country="false"
    default-country="ZA"
  />
</template>
