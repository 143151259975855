<script setup lang="ts">
import { computed } from "vue";
import { isObject, findIndex } from "lodash";
import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/vue";

const props = defineProps<Props>();

interface Props {
  context: any;
}

const selectedValue = computed(() => {
  if (!modelValue.value) return props.context.placeholder;
  if (props.context.options.some((item: any) => isObject(item)) && props.context.trackBy) {
    const index = findIndex(props.context.options, [props.context.trackBy, modelValue.value[props.context.trackBy] ?? modelValue.value]);
    return props.context.options[index].name;
  }
  return modelValue.value;
});

const trackBy = (item: any) => {
  if (!item) return;
  if (props.context.trackBy) return item[props.context.trackBy];
  return item;
};

const returnBy = (item: any) => {
  if (!item) return;
  if (props.context.returnBy) return item[props.context.returnBy];
  return item;
};

const isSelected = (selected: boolean) => {
  if (!modelValue.value) return false;
  return selected;
};

const modelValue = computed({
  get() {
    return props.context._value;
  },
  set(value) {
    if (typeof value === "string") value.trim();
    props.context.node.input(value);
  },
});
</script>

<template>
  <div class="w-full">
    <Listbox v-model="modelValue">
      <div class="relative mt-1">
        <ListboxButton
          #default="{ open }"
          class="relative w-full cursor-default border rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:outline-none focus-visible:border-primary focus-visible:ring-1 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-1 focus-visible:ring-offset-primary sm:text-sm"
        >
          <span :class="modelValue ? 'text-dark-gray' : 'text-med-gray'" class="block text-dark-gray truncate">{{ selectedValue }}</span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <BaseIcon icon="Fa6SolidChevronUp" :class="[open ? 'rotate-180' : 'rotate-0 ', 'text-med-gray transform transition-all']" />
          </span>
        </ListboxButton>

        <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
          <ListboxOptions
            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white z-30 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              v-slot="{ active, selected }"
              v-for="(item, index) in context.options"
              :key="index"
              :value="returnBy(item)"
              as="template"
            >
              <li
                class=""
                :class="[
                  { 'bg-primary bg-opacity-20 ': active && !isSelected(selected) },
                  { 'bg-primary ': isSelected(selected) },
                  isSelected(selected) ? 'text-white' : 'text-dark-gray',
                  'relative cursor-pointer select-none py-2 px-4 ',
                ]"
              >
                <span :class="[isSelected(selected) ? 'font-medium ' : 'font-normal', 'block truncate']">{{ trackBy(item) }}</span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
  </div>
</template>
