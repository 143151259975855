<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed } from "vue";
import { Loader } from "@googlemaps/js-api-loader";

interface Place {
  formatted_address: string;
  geometry: google.maps.places.PlaceGeometry;
  name: string;
  vicinity: string;
}

interface Props {
  context: any;
}

const props = defineProps<Props>();

const GMAP_API_KEY = import.meta.env.PUBLIC_GMAP_API_KEY;
const inputRef = ref<HTMLInputElement | null>(null);
const place = ref<Place | null>(null);
let autocomplete: google.maps.places.Autocomplete;

const initAutoComplete = () => {
  if (!window.google || !inputRef.value) return;
  autocomplete = new window.google.maps.places.Autocomplete(inputRef.value, {
    fields: ["formatted_address", "geometry", "name", "vicinity"],
    componentRestrictions: { country: "za" },
  });
  window.google.maps.event.addListener(autocomplete, "place_changed", () => {
    place.value = autocomplete.getPlace() as Place;
    if (place.value.geometry) {
      const lat = place.value.geometry.location?.lat();
      const lng = place.value.geometry.location?.lng();
      const locationData = { ...place.value, lng, lat };
      props.context.node.input({
        location_name: inputRef.value?.value,
        location_data: locationData,
      });
    }
    place.value = null;
  });
};

const modelValue = computed(() => props.context._value?.location_name ?? "");

onMounted(async () => {
  const loader = new Loader({
    apiKey: GMAP_API_KEY,
    version: "weekly",
    libraries: ["places"],
  });
  await loader.load();
  initAutoComplete();
});

onUnmounted(() => {
  if (autocomplete) {
    google.maps.event.clearInstanceListeners(autocomplete);
  }
});
</script>

<template>
  <div>
    <div class="relative border border-solid border-light-gray rounded-md focus-within:shadow-md focus-within:border-primary">
      <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <BaseIcon icon="Fa6SolidLocationDot" class="h-4 w-4" />
      </div>
      <input
        v-model="modelValue"
        id="autocomplete"
        ref="inputRef"
        placeholder="Search for location"
        class="w-full border-0 pl-10 placeholder-med-gray focus:ring-0 text-sm rounded-md"
        type="text"
      />
    </div>
  </div>
</template>
