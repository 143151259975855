import type { App } from "vue";
import { plugin, defaultConfig } from "@formkit/vue";
import config from "@/packages/formkit/formkit.config";

import { FontAwesomeIcon } from "@/packages/font-awesome/font-awesome";
import BaseButton from "@/components/ui-elements/BaseButton.vue";
import BaseIconButton from "@/components/ui-elements/BaseIconButton.vue";
import BaseMenu from "@/components/ui-elements/BaseMenu.vue";
import BaseMenuItem from "@/components/ui-elements/BaseMenuItem.vue";
import BaseIcon from "@/components/ui-elements/BaseIcon.vue";
import BaseAvatar from "@/components/ui-elements/BaseAvatar.vue";
import BaseImage from "@/components/ui-elements/BaseImage.vue";

import { createPinia } from "pinia";
import { addIcons } from "@/helpers/add-icons";
import "@/styles/vtour.scss";

const pinia = createPinia();

export default (app: App) => {
  app
    .use(plugin, defaultConfig(config))
    .use(pinia)
    .component("font-awesome-icon", FontAwesomeIcon)
    .component("BaseButton", BaseButton)
    .component("BaseIconButton", BaseIconButton)
    .component("BaseMenu", BaseMenu)
    .component("BaseMenuItem", BaseMenuItem)
    .component("BaseIcon", BaseIcon)
    .component("BaseAvatar", BaseAvatar)
    .component("BaseImage", BaseImage);
  addIcons(app);
  return app;
};
