import { generateClasses } from "@formkit/themes";
import { createInput } from "@formkit/vue";
import { createAutoAnimatePlugin, createLocalStoragePlugin } from "@formkit/addons";
import FormkitSelect from "@/components/formkit/FormkitSelect.vue";
import FormkitSlider from "@/components/formkit/FormkitSlider.vue";
import FormkitTelInput from "@/components/formkit/FormkitTelInput.vue";
import FormkitGMapAutoComplete from "@/components/formkit/FormkitGMapAutoComplete.vue";
import theme from "./theme";

const config = {
  config: {
    classes: generateClasses(theme),
  },
  inputs: {
    FormkitSelect: createInput(FormkitSelect, {
      props: ["trackBy", "returnBy", "placeholder", "options"],
    }),
    FormkitSlider: createInput(FormkitSlider, {
      props: ["min", "max", "currencySymbol"],
    }),
    FormkitGMapAutoComplete: createInput(FormkitGMapAutoComplete, {
      props: [],
    }),
    FormkitTelInput: createInput(FormkitTelInput, {
      props: ["placeholder"],
    }),
  },
  plugins: [
    createAutoAnimatePlugin(),
    createLocalStoragePlugin({
      prefix: "formkit",
      maxAge: 3600000,
    }),
  ],
};

export default config;
