import type { App } from "vue";
import Fa6SolidCirclePlus from "~icons/fa6-solid/circle-plus";
import Fa6SolidCircleQuestion from '~icons/fa6-solid/circle-question'
import Fa6SolidPlus from "~icons/fa6-solid/plus";
import Fa6SolidBars from "~icons/fa6-solid/bars";
import Fa6SolidXmark from "~icons/fa6-solid/xmark";
import Fa6SolidChevronRight from "~icons/fa6-solid/chevron-right";
import Fa6SolidChevronLeft from "~icons/fa6-solid/chevron-left";
import Fa6SolidChevronUp from "~icons/fa6-solid/chevron-up";
import Fa6SolidFaceFrown from "~icons/fa6-solid/face-frown";
import Fa6SolidCircleCheck from "~icons/fa6-solid/circle-check";
import Fa6SolidCircleExclamation from "~icons/fa6-solid/circle-exclamation";
import Fa6SolidCircleXmark from "~icons/fa6-solid/circle-xmark";
import Fa6SolidCheck from "~icons/fa6-solid/check";
import Fa6SolidLocationDot from "~icons/fa6-solid/location-dot";
import Fa6SolidPen from "~icons/fa6-solid/pen";
import Fa6SolidTrash from "~icons/fa6-solid/trash";
import Fa6SolidEllipsisVertical from "~icons/fa6-solid/ellipsis-vertical";
import Fa6SolidPenToSquare from "~icons/fa6-solid/pen-to-square";
import Fa6SolidHouse from "~icons/fa6-solid/house";
import Fa6SolidTent from "~icons/fa6-solid/tent";
import Fa6SolidShop from "~icons/fa6-solid/shop";
import Fa6SolidUser from "~icons/fa6-solid/user";
import Fa6SolidCreditCard from "~icons/fa6-solid/credit-card";
import Fa6SolidGear from "~icons/fa6-solid/gear";
import Fa6SolidBell from "~icons/fa6-solid/bell";
import Fa6SolidRightFromBracket from "~icons/fa6-solid/right-from-bracket";
import Fa6SolidIdCard from "~icons/fa6-solid/id-card";
import Fa6SolidFileInvoice from "~icons/fa6-solid/file-invoice";

export function addIcons(app: App) {
  app
    .component("Fa6SolidCheck", Fa6SolidCheck)
    .component("Fa6SolidCircleQuestion", Fa6SolidCircleQuestion)
    .component("Fa6SolidCircleCheck", Fa6SolidCircleCheck)
    .component("Fa6SolidCirclePlus", Fa6SolidCirclePlus)
    .component("Fa6SolidPlus", Fa6SolidPlus)
    .component("Fa6SolidBars", Fa6SolidBars)
    .component("Fa6SolidXmark", Fa6SolidXmark)
    .component("Fa6SolidChevronRight", Fa6SolidChevronRight)
    .component("Fa6SolidChevronLeft", Fa6SolidChevronLeft)
    .component("Fa6SolidChevronUp", Fa6SolidChevronUp)
    .component("Fa6SolidFaceFrown", Fa6SolidFaceFrown)
    .component("Fa6SolidCircleExclamation", Fa6SolidCircleExclamation)
    .component("Fa6SolidCircleXmark", Fa6SolidCircleXmark)
    .component("Fa6SolidLocationDot", Fa6SolidLocationDot)
    .component("Fa6SolidPen", Fa6SolidPen)
    .component("Fa6SolidTrash", Fa6SolidTrash)
    .component("Fa6SolidEllipsisVertical", Fa6SolidEllipsisVertical)
    .component("Fa6SolidPenToSquare", Fa6SolidPenToSquare)
    .component("Fa6SolidHouse", Fa6SolidHouse)
    .component("Fa6SolidTent", Fa6SolidTent)
    .component("Fa6SolidShop", Fa6SolidShop)
    .component("Fa6SolidUser", Fa6SolidUser)
    .component("Fa6SolidCreditCard", Fa6SolidCreditCard)
    .component("Fa6SolidGear", Fa6SolidGear)
    .component("Fa6SolidBell", Fa6SolidBell)
    .component("Fa6SolidRightFromBracket", Fa6SolidRightFromBracket)
    .component("Fa6SolidIdCard", Fa6SolidIdCard)
    .component("Fa6SolidFileInvoice", Fa6SolidFileInvoice);
}
