// Create some re-useable definitions because
// many input types are identical in how
// we want to style them.
const textClassification = {
  label: "block mb-1 text-dark-gray font-semibold text-xs ",
  inner: `
      border
      border-light-gray
      formkit-invalid:border-danger
      rounded-lg
      overflow-hidden
      focus-within:border-primary
      focus-within:shadow-md 
    `,
  input: "w-full px-3 py-2 border-none text-sm text-dark-gray placeholder-med-gray",
};

const selectClassification = {
  label: "block mb-1 text-dark-gray font-semibold text-xs ",
  inner: `
      border
      border-light-gray
      formkit-invalid:border-danger
      rounded-lg mb-1
      overflow-hidden
      focus-within:border-primary
      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
    `,
  input:
    "w-full px-3 py-2 border-none text-sm text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary",
};
const boxClassification = {
  fieldset: "max-w-md border border-light-gray rounded-md px-2 pb-1",
  legend: "font-semibold text-xs",
  wrapper: "flex items-center cursor-pointer w-fit",
  help: "mb-2",
  input:
    "form-check-input appearance-none h-5 w-5 mr-2 border border-light-gray rounded-md bg-white checked:bg-primary text-primary focus:outline-none focus:ring-1 transition duration-200",
  label: "text-xs font-semibold mt-1",
};
const buttonClassification = {
  wrapper: "mb-1",
  input: "bg-primary hover:bg-blue-700 text-white text-sm py-3 px-5 rounded-lg hover:shadow-lg shadow-sm font-semibold",
};

// export our definitions using our above
// templates and declare one-offs and
// overrides as needed.
export default {
  // the global key will apply to all inputs
  global: {
    outer: "formkit-disabled:opacity-50",
    help: "text-xs text-gray-500",
    messages: "list-none p-0 mt-1 mb-0",
    message: "text-danger mb-1 text-xs",
    input: "text-dark-gray",
    label: "block mb-1 text-dark-gray font-semibold text-xs ",
  },
  button: buttonClassification,
  color: {
    label: "block mb-1 font-bold text-sm",
    input: "w-16 h-8 appearance-none cursor-pointer border border-gray-300 rounded-md mb-2 p-1",
  },

  date: textClassification,
  "datetime-local": textClassification,
  checkbox: boxClassification,
  email: textClassification,
  file: {
    label: "block mb-1 font-bold text-sm",
    inner: "max-w-md cursor-pointer",
    input:
      "text-gray-600 text-sm mb-1 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:bg-primary file:text-white hover:file:bg-blue-600",
    noFiles: "block text-gray-800 text-sm mb-1",
    fileItem: "block flex text-gray-800 text-sm mb-1",
    fileRemove: "ml-auto text-primary text-sm",
  },
  month: textClassification,
  number: textClassification,
  password: textClassification,
  radio: {
    ...boxClassification,
    input: boxClassification.input.replace("rounded-sm", "rounded-full"),
  },
  range: {
    inner: "max-w-md",
    input: "form-range appearance-none w-full h-2 p-0 bg-gray-200 rounded-full focus:outline-none focus:ring-0 focus:shadow-none",
  },
  search: textClassification,
  select: selectClassification,
  submit: buttonClassification,
  tel: textClassification,
  text: textClassification,
  textarea: {
    ...textClassification,
    input: "block w-full h-32 px-3 border-none placeholder-med-gray text-sm",
  },
  time: textClassification,
  url: textClassification,
  week: textClassification,
};
